@font-face {
  font-family: Arial;
  src: url('./fonts/arial/ARIAL.TTF');

  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url('./fonts/poppins/Poppins-Regular.ttf');

  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Play;
  src: url('./fonts/play/Play-Regular.ttf');

  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Charmonman;
  src: url('./fonts/charmonman/Charmonman-Regular.ttf');

  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Nova Cut;
  src: url('./fonts/nova_cut/NovaCut-Regular.ttf');

  font-weight: 400;
  font-style: normal;
  font-display: swap;
}


html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

.bg-big {
  display: block;
}

.bg-middle,
.bg-small,
.etc-span {
  display: none;
}

.languages-select {
  background: transparent !important;
}


h3 {
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  /* identical to box height, or 117% */


  /* Black n White/Main Black - heading */

  color: #152141;
}

.ad-container {

  display: none !important;
}

.already-added-div {
  button {
    width: 100% !important;
  }

}

.m-right-28 {
  margin-right: 28px;
}

.start-modes {
  margin-bottom: 28px;
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}

.MuiClockPicker-arrowSwitcher {

  .MuiIconButton-edgeEnd,
  .Mui-disabled,
  .MuiIconButton-root {
    background-color: transparent !important;
    color: rgba(0, 0, 0, 0.26) !important;
    border: none !important;
  }
}

.sub-operations-div {
  margin-top: 12px !important;

  .sub-operations {


    margin-top: 0 !important;
  }
}

.single-timer-div {
  width: 100% !important;
  //height: 326px !important;

  .sub-operations-div {
    margin-top: 0 !important;
    justify-content: center !important;
  }

  .progress-bar {
    justify-content: center !important;

    .progress-bar-info {
      width: 60% !important;
    }
  }

  .MuiLinearProgress-root {
    height: 12px !important;

  }

  .simple-display-operations {

    position: absolute;
    right: 40px;
  }

  .timer-started-info {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    text-align: left;
    font-size: 24px;
    line-height: 28px;
    /* identical to box height, or 117% */


    /* Black n White/Main Black - heading */

    color: #152141;
  }

  .time {
    width: 100%;





    .countdown-div {
      font-weight: 400 !important;
      font-size: 100px !important;
      line-height: 100px !important;
      height: auto;
      /* identical to box height, or 100% */
      text-align: center;
      /* Black n White/Main Black - heading */
      color: #152141;
    }
  }
}

.mobile-logo {
  display: none;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.Mui-disabled {
  color: white !important;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.for-timer-title {
  margin-bottom: -5px;
}

.for-timer-inner-title {
  margin-top: 0;

  width: 100% !important;

  h5 {
    margin-top: 0;
    margin-bottom: 10px;
    text-align: left !important;
    color: #8A90A0 !important;
  }
}

* {
  box-sizing: border-box;
}

.MuiPickersDay-root.Mui-selected {
  background-color: rgb(3, 201, 215) !important;
}

.tools-app {
  .dropDown-div {
    position: absolute;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;

    /* Black n White/White */

    background: #FFFFFF;
    /* Shadow dark 10% (Y=0) */

    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    height: 178.5px;
    width: 312px;
    left: 0px;
    top: 0px;
    top: 75px;
    z-index: 7;
    padding: 20px;

    .dropDown-operations {
      display: flex;

      justify-content: flex-end;
      column-gap: 24px;
      margin-top: 12px;

      font-weight: 700;
      font-size: 16px;
      line-height: 25px;
      /* identical to box height, or 156% */


      /* Black n White/Black 80% - paragraph */

      color: #444D67;

      div:hover {
        cursor: pointer;
      }

      .ok {
        color: #56A269;
      }
    }

    .times {
      align-items: center;
      display: flex;
      justify-content: space-around;
      margin-top: 12px;

      .simple-select__control {
        width: 80px;
        font-family: 'Arial';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        /* identical to box height, or 160% */


        /* Black n White/Black 80% - paragraph */

        color: #444D67;
      }


      .title {

        h5 {
          font-family: 'Arial';
          font-style: normal;
          font-weight: 400;
          font-size: 13px !important;
          line-height: 22px !important;
          margin-bottom: 4px !important;
          margin-top: 0 !important;
          /* identical to box height, or 169% */


          /* Black n White/Black 50% */

          color: #8A90A0;
        }
      }

    }

    .am-pm-group {
      button {
        box-sizing: border-box;

        height: 22px;
        min-width: 36px;

        /* Black n White/White */

        background: #FFFFFF;
        /* Black n White/Black 10% */

        border: 1px solid #E8E9EC;
        border-radius: 20px;
        font-family: 'Arial';
        font-style: normal;
        font-weight: 700;
        font-size: 11px;
        line-height: 18px;
        text-transform: uppercase !important;
        color: #B9BCC6;
      }

      .selected-button {
        background: rgb(3, 201, 215);
        color: #fff;
        border: 1px solid rgb(3, 201, 215);
      }

      .amButton {
        border-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      .pmButton {
        border-left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    .popover-arrow {
      position: absolute;
      top: -10px;
      left: 60px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid white;
      width: 0;
      height: 0;
      -webkit-filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.1));


    }


  }



  .nav_active {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 13px 16px !important;
    margin-left: 14px !important;
    margin-right: 14px;

    box-shadow: 0px 4px 10px rgb(0 0 0 / 7%);
    border-radius: 4px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    background: #1E2F5C;
  }


  .digital_clock_style {
    font-family: Arial;
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 52px;
    /* identical to box height, or 108% */


    /* Black n White/Main Black - heading */

    color: #152141;

  }

  .digital_clock_title {
    position: absolute;
    top: 15px;
    left: 15px;
    font-size: 16px;


  }

  .digital_clock_title p {
    margin: 0;
  }

  .embed-selects {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .remove_icon {
    position: absolute;
    right: 2px;
    top: 7px;

  }

  .m-bottom-20 {
    margin-bottom: 20px;
  }

  .m-bottom-24 {
    margin-bottom: 24px;
  }

  .embed-div {
    padding-top: 28px;
  }



  .m-w-250 {
    min-width: 250px;
  }

  .w-100 {
    width: 100% !important;
  }

  .m-right-15 {
    margin-right: 15px;
  }

  .stopwatch__display {
    text-align: center;
    font-size: 35px;
  }



  .f-size-35 {
    font-size: 35px;
  }

  .stopwatch__history {
    text-align: center;
    font-size: 20px;
  }

  .stopwatch__history .m-bottom-20 {
    margin-bottom: 0;
  }

  .stopwatch__history ul {
    display: inline-block;
  }

  .stopwatch__history .stopwatch__display {
    font-size: 20px;
  }

  .toglelike {
    display: flex;
  }

  .toglelike .toggle-like-each {
    align-items: center;
    border: 1px solid transparent;
    border-radius: 15px;
    padding-left: 7px;
    padding-right: 7px;

    margin-right: 12px;

    border-top-left-radius: 50px !important;
    border-top-right-radius: 50px !important;
    border-bottom-left-radius: 50px !important;
    border-bottom-right-radius: 50px !important;
    text-transform: capitalize !important;
    margin-bottom: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    justify-content: center;
    align-items: center;
    padding: 5px 15px;
    grid-gap: 10px;
    gap: 10px;
    min-width: 100px;
    min-height: 34px;
    background: #F3F4F6;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #8A90A0;
    border-radius: 50px;
    text-align: center;
  }

  .operation-button {
    cursor: pointer;
  }

  .toglelike-inner {
    align-items: center;
    justify-content: space-between;
    display: flex;
    margin-right: 18px;
  }



  .toglelike .toggle-like-each:hover {
    text-decoration: none;
    cursor: pointer;

    background: #F3F4F6;
    border: 1px solid rgb(3, 201, 215);
    color: #8A90A0;
  }

  .toglelike .selected {

    background: rgb(3, 201, 215) !important;
    color: #152141 !important;
  }



  .stopwatch__history ul {
    transform: rotate(180deg);
  }

  .stopwatch__history ul>li {
    transform: rotate(-180deg);
  }

  .span_display {
    min-width: 70px;
    display: inline-block
  }

  .stopwatch__history .span_display {
    min-width: none;
  }

  .index_style {
    min-width: 30px;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }

  .margin-left-12 {
    margin-left: 12px;
  }



  .flex-50 {
    justify-content: flex-end;
    display: flex;
  }

  .flex-form {
    display: flex;
    flex-direction: unset;
    flex-wrap: wrap;
    width: 65%;
    align-items: center;
  }

  .flex-form .title {
    width: 40%;
    margin-right: 30px;
    word-break: break-all;
  }

  .flex-form .field {
    width: 50%;
  }

  .field [role="group"] button {
    border-radius: 50px;
    margin-right: 12px;

    border: 0;
    border-top-left-radius: 50px !important;
    border-top-right-radius: 50px !important;
    border-bottom-left-radius: 50px !important;
    border-bottom-right-radius: 50px !important;
    text-transform: capitalize !important;

    margin-bottom: 10px;
    padding-top: 5px;
    padding-bottom: 5px;

    justify-content: center;
    align-items: center;
    padding: 5px 15px;
    gap: 10px;

    width: 56px;
    height: 34px;

    /* Black n White/Black 5% */

    background: #F3F4F6;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    /* identical to box height, or 160% */


    /* Black n White/Black 50% */

    color: #8A90A0;
  }

  .field .Mui-selected {
    background: rgb(3, 201, 215) !important;
    color: #152141 !important;
  }

  .field .MuiToggleButton-root:hover {
    background: #F3F4F6;
    /* Accent Green/Green Subtle */

    border: 1px solid rgb(3, 201, 215);
    color: #8A90A0;
  }

  .toglelike-inner:hover .operation-button svg path {
    fill: rgb(3, 201, 215);
  }

  .toglelike .selected-inner .operation-button svg path {
    fill: rgb(3, 201, 215);
  }

  .timepicker-div .field .MuiFormControl-root .MuiInputBase-formControl {
    height: 70px;
    font-size: 32px;
    line-height: 40px;

    padding: 15px 20px;
    width: 178px;

    position: relative;
  }

  .field .MuiFormControl-root input[type="text"] {
    padding: 0;
  }

  .MuiInputBase-formControl {

    //line-height: 40px;
    color: #152141;
    border-radius: 4px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    color: #152141;
    background: #FFFFFF;
  }

  .MuiAccordionSummary-root {
    align-items: center;
    padding: 16px 30px;
    gap: 10px;


    height: 72px;

    /* Black n White/Black 3% - Lightbox */
    box-shadow: none;
    background: #F8F8F9;
    border-radius: 4px;


  }

  .MuiAccordionSummary-content {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    text-transform: capitalize;
    /* identical to box height, or 130% */


    /* Black n White/Main Black - heading */

    color: #152141;
  }

  .MuiCollapse-container {
    transition-duration: 0s !important;
    transform: 'none !important'
  }

  .MuiExpansionPanelSummary-expandIcon.Mui-expanded {

    transform: none !important;
  }

  .inner-title {
    /* body/body (16-25) */

    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    /* identical to box height, or 156% */

    text-align: center;

    /* Black n White/Black 50% */

    color: #8A90A0;
    text-transform: capitalize;
    margin: 0 auto;
  }

  .faq-title {
    margin-bottom: 8px !important;
    margin-top: 28px;
  }

  .faq-container {
    width: 100%;
    margin-top: 28px;
  }

  .MuiAccordionDetails-root {
    background: #F8F8F9;
    box-shadow: none;
    border-radius: 4px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #444D67;
    padding: 16px 30px;
    padding-top: 7px;
  }

  .MuiAccordion-root {
    box-shadow: none;
    border-radius: 4px;
    margin-bottom: 28px;
  }

  .MuiAccordion-root:before {
    height: 0;
  }

  .info-block-each-text {
    width: 261px;
    padding-left: 24px;
    padding-right: 24px;
    word-break: break-word;

    /* body/body (16-25) */

    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    /* or 156% */

    text-align: center;

    /* Black n White/Main Black - heading */

    color: #152141;

  }

  .text-input .MuiInputBase-formControl {
    padding: 13px 16px;
    gap: 10px;

    width: 283px;
    height: 50px;
  }

  .simple-select__control {
    height: 50px;

    width: 178px;
    /* Black n White/White */

    background: #FFFFFF;
    /* Black n White/Black 10% */

    border: 1px solid #E8E9EC;
    border-radius: 4px;
  }

  .simple-select__control:hover {
    border: 1px solid rgb(3, 201, 215);
  }

  /* Scroll Bar */
  .simple-select__menu-list::-webkit-scrollbar {
    width: 14px !important;
    height: 6px !important;
  }

  .simple-select__menu-list::-webkit-scrollbar-track {

    background: #E8E9EC;
    border-radius: 10px;
    transform: rotate(90deg);
  }

  .simple-select__menu-list::-webkit-scrollbar-thumb {

    background: rgb(3, 201, 215);
    border-radius: 10px;
    transform: rotate(90deg);
  }

  .simple-select__menu-list::-webkit-scrollbar-thumb:hover {

    background: rgb(3, 201, 215);
    border-radius: 10px;
    transform: rotate(90deg);
  }







  .simple-select__menu {
    background: #FFFFFF;
    border: 1px solid rgb(3, 201, 215);
    box-shadow: 0px 4px 10px rgb(0 0 0 / 7%);
    border-radius: 4px;
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    margin-top: 0;
  }

  .has-error {
    background: red;
  }

  /*.simple-select__menu-notice--no-options*/


  /* Scroll Bar */
  select__menu-list::-webkit-scrollbar {
    width: 4px !important;
    height: 0px !important;
  }

  select__menu-list::-webkit-scrollbar-track {
    background: #f1f1f1 !important;
  }

  select__menu-list::-webkit-scrollbar-thumb {
    background: red !important;
  }

  select__menu-list::-webkit-scrollbar-thumb:hover {
    background: red !important;
  }



  .MuiFormControl-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgb(3, 201, 215);
  }


  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid rgb(3, 201, 215) !important;
  }

  .simple-select__control--menu-is-open {
    border: 1px solid rgb(3, 201, 215) !important;
  }

  .timepicker-div .field .MuiFormControl-root .MuiInputBase-formControl input {
    padding: 0;
  }

  .timepicker-div .field .MuiFormControl-root .MuiInputBase-formControl .MuiInputAdornment-root {
    position: absolute;
    right: -50px;
  }



  fieldset {
    border: 1px solid #E8E9EC;
    border-radius: 4px;
  }

  .field [role="group"] {
    display: flex;
    flex-wrap: wrap;
  }

  .yellow-btn-div {
    button {
      background: rgb(251, 150, 120) !important;
    }

  }

  .btn-div button:hover {
    background: #80C391;
    box-shadow: none;

  }

  .btn-div button:focus {
    background: #56A269;
    box-shadow: none;
  }

  .btn-div button {
    box-shadow: none;
    text-transform: uppercase !important;

    padding: 15px 28px;
    gap: 10px;
    /* Subtitle (16-20) buttons */

    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    text-transform: uppercase;

    /* Black n White/White */

    color: #FFFFFF;

    width: 177px;
    height: 50px;


    /* Accent Green/Main green */

    background: rgb(3, 201, 215);
    border-radius: 4px;
  }

  .embed-btn-div button {
    // width: 146px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    text-transform: uppercase;

    /* Black n White/White */

    color: #FFFFFF;

  }



  .embed-size {
    width: 50%;
  }

  .embed-code {
    width: 100%;
    margin-top: 52px;
  }



  .embed-code textarea {

    width: 100% !important;
    height: 101px !important;
    border: 1px solid #E8E9EC;
    border-radius: 4px;
  }

  .preview-embed {
    width: 100%;
    height: 324px;

    /* Black n White/Black 3% - Lightbox */

    background: #F8F8F9;
    border-radius: 4px;
  }

  .embed-size .MuiFormControl-root {
    width: 100%;

  }

  .select-size {
    height: 50px !important;
  }

  label[for="select-size"] {
    /* hearding/H5 bold (18-24)+H3_mobile */

    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */


    /* Black n White/Main Black - heading */

    color: #152141;
    margin-bottom: 8px;
  }

  .custom-MuiMenu-list {
    right: 165px;
    left: auto !important;

    .Mui-selected {
      background-color: transparent !important;
    }
  }

  .MuiMenu-list {
    display: flex;
    flex-wrap: wrap;


    padding-left: 20px;
    padding-right: 20px;
    width: 300px;
    //height: 372px;

    .text-language-div {
      font-family: 'Arial';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 22px;
      /* identical to box height, or 169% */


      /* Black n White/Black 80% - paragraph */

      color: #444D67;

    }

  }

  .MuiMenu-list li {
    width: 50%;
    padding-left: 0;
  }

  .embed-display {
    width: 600px !important;
    align-items: center;
    margin: 0 auto;
    margin-top: 200px;
  }

  .MuiPaper-root {
    background: #F8F8F9;
    /* Shadow dark 7% */

    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07);
    border-radius: 4px;
  }

  .left-menu .MuiPaper-root {
    border-radius: 0;
  }

  .error-btn-div button {
    border: 1px solid #d32f2f;
    border-radius: 4px;
    color: #d32f2f;
    background: white;

    &:hover {
      background: white;
      box-shadow: none;
      color: #d32f2f;
      border: 1px solid #d32f2f;
    }
  }

  .snooze-btn-div button {
    border: 1px solid rgb(3, 201, 215) !important;
    border-radius: 4px;
    color: #56A269 !important;
    background: white !important;

    &:hover {
      background: white;
      box-shadow: none;
      color: #56A269;
      border: 1px solid rgb(3, 201, 215);
    }
  }

  .regular-outlined-button {
    display: flex;


    button {
      height: 50px;
      font-family: "Arial";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      text-transform: uppercase !important;
    }
  }

  .alarm-box .time {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 52px;
    color: #152141;
  }

  .preview-embed-inner {
    height: 324px;
  }

  .embedAddAlarm {
    width: 600px !important;
  }

  .alarms-title {
    /* H3 bold (32-40) */

    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    /* identical to box height, or 125% */

    text-align: center;

    /* Black n White/Main Black - heading */

    color: #152141;
    margin-bottom: 28px;
  }

  .alarm-box .title {

    padding-bottom: 5px;
    text-align: left;

  }

  .alarm-box {

    padding: 28px 40px;
    gap: 12px;

    width: 491px;
    height: 220px;
    margin-bottom: 40px;

    /* Black n White/White */

    background: #FFFFFF;
    /* Shadow dark 7% */

    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07);
    border-radius: 4px;
  }

  .flex-div,
  .MuiSelect-select {
    display: flex;
    justify-content: space-between;

    align-items: center;
    flex-wrap: wrap;
    min-width: 85px;
  }

  .MuiSelect-icon {
    color: #444D67;
    margin-top: -1px;
  }

  .languages .MuiOutlinedInput-root {
    height: 40px;
    margin: 0;
  }

  .languages .MuiFormControl-root {
    margin: 0;
  }

  .left-align {
    justify-content: left;
  }

  .export-laps {
    .MuiButton-outlined {
      height: 40px;
    }
  }

  .m-top-40 {
    margin-top: 40px !important;
  }

  .m-top-20 {
    margin-top: 20px !important;
  }

  .m-top-28 {
    margin-top: 28px !important;
  }

  .small-ad {
    width: 980px !important;
    height: 120px !important;
    margin-top: 40px !important;
  }

  .md-ad {

    width: 930px !important;
    height: 180px !important;
    margin-top: 40px !important;
  }

  .bg-ad {

    width: 980px !important;
    height: 250px !important;
    margin-top: 12px !important;
  }

  .ad-container {
    box-sizing: border-box;

    /* Auto layout */

    justify-content: center;
    align-items: center;

    gap: 10px;


    background: #FFFFFF;
    border: 1px solid #E8E9EC;
  }

  .rating-container {
    width: 594px !important;
  }

  .total-rating-stars .MuiRating-root {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .total-rating-info {
    display: flex;
    justify-content: space-between;
    margin: auto;
    margin-top: 16px;
  }

  .total-number {
    margin-right: 8px;
  }

  .rating-container-inner {
    display: flex;

    justify-content: center;
    align-items: center;
    padding: 28px 60px;
    gap: 10px;
    margin-top: 28px;
    margin-bottom: 60px;

    width: 100%;
    height: 230px;

    /* Black n White/White */

    background: #FFFFFF;
    /* Shadow dark 7% */

    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07);
    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
  }

  .total-rating-title {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    /* identical to box height, or 169% */


    /* Black n White/Black 80% - paragraph */

    color: #444D67;
    margin: 0 auto;
    text-transform: capitalize;
  }

  .total-rating {
    width: 144px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .total-number {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    color: #444D67;
  }

  .total-count {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    color: #8A90A0;
  }

  .total-rating-stars {

    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgb(0 0 0 / 5%);
    border-radius: 70px;
    width: 100%;
    display: flex;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 20px;
    margin-top: 16px;
  }

  .MuiBox-root {
    position: relative;
    //overflow: scroll;
  }

  .main-component {
    // overflow-x: scroll !important;
  }

  .footer {
    display: flex;
    border-top: 1px solid #E8E9EC;
    position: absolute;
    width: 100%;
    right: 0px;
    height: 122px;
  }

  .copy-text {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    /* identical to box height, or 169% */


    color: #444D67;
  }

  .footer-container-inner {
    padding-left: 30px;
    padding-right: 30px;

    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .rating-form .show-value {
    width: 54px;
    height: 54px;
    left: 78px;
    /* H3 bold (32-40) */

    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    /* identical to box height, or 125% */

    text-align: center;

    /* Accent Green/Green Dark */

    color: #56A269;


    /* Black n White/White */

    background: #FFFFFF;
    /* Shadow dark 5% */

    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 140px;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .rating-form {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .rating-start {
    width: 200px;
    margin-top: 16px;
  }

  .slider-div {
    width: 200px;
    margin-top: 19px;
  }

  .rating-start .MuiRating-root {
    display: flex;
    justify-content: space-between;
  }

  .MuiSlider-root:hover .MuiSlider-rail {
    background: #E8E9EC;
  }

  .MuiSlider-rail {
    /* Black n White/Black 20% */


    background: #DADDE3;
    border-radius: 40px;
  }

  .MuiSlider-track {
    background: rgb(3, 201, 215);
    /* Accent Green/Main green */

    border: 1px solid rgb(3, 201, 215);
    border-radius: 20px;
  }

  .MuiSlider-thumb {
    /* Black n White/White */

    background: #FFFFFF;
    width: 24px;
    height: 24px;
    /* Accent Green/Main green */

    border: 4px solid rgb(3, 201, 215);
  }

  .Mui-focusVisible::before,
  .Mui-focusVisible::after {
    box-shadow: none !important;
  }

  .MuiSlider-thumb:focus,
  .MuiSlider-thumb:hover,
  .Mui-focusVisible {
    box-shadow: none !important;
    transition: none !important;
  }


  .MuiSlider-thumb:before {

    box-shadow: none !important;
  }

  .MuiSlider-root:hover .MuiSlider-thumb {
    /* Black n White/White */

    background: #FFFFFF;
    /* Accent Green/Green Light */

    border: 4px solid #80C391;
    box-shadow: none;
  }

  .delimeter-line {
    width: 100px;
    height: 0px;

    /* Black n White/Black 5% */

    border: 1px solid #F3F4F6;
    transform: rotate(90deg);
  }

  .rating-tatle {
    /* body/menu bold (15-24) */

    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    margin-top: 16px;
    /* identical to box height, or 160% */

    text-align: center;

    /* Black n White/Main Black - heading */

    color: #152141;
    text-transform: capitalize;
  }

  .info-block {
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07);
    display: flex;
    border-radius: 20px;
    min-height: 196px;
    padding: 24px 28px;
  }

  .info-block-each {
    // width: 317px;


    /* Black n White/White */


    /* Shadow dark 7% */


    border-radius: 4px;

    align-items: center;
    // flex-direction: column;
    row-gap: 20px;
    justify-content: flex-start;

    .info-block-each-icon {
      //  margin-top: 28px;
      // height: 43px;
      //  width: 43px;
    }
  }

  .ad-text {
    margin: 0 auto;
    text-align: center;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #96A1AB;
  }

  .MuiButton-outlined {
    border: 1px solid rgb(3, 201, 215);
    border-radius: 4px;
    color: #56A269;
    font-family: Arial;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
  }

  .MuiButton-outlined:hover {
    background: #80C391;
    color: white;
    // border: 1px solid transparent;
  }

  .MuiButton-outlined:active {
    background: #56A269;
    color: white;
    border: 1px solid transparent;
  }

  .MuiButton-outlined:hover svg path,
  .MuiButton-outlined:active svg path {
    fill: white;
  }

  .MuiToolbar-root {
    display: flex;
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 30px;
    min-height: 72px;
  }

  .MuiSelect-select {
    font-family: Arial;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;

    /* identical to box height, or 160% */


    /* Black n White/Black 80% - paragraph */

    color: #444D67;

  }

  .languages .MuiOutlinedInput-root fieldset {
    border: none !important;
  }

  .on-off button {
    border-radius: 25px;
    padding-bottom: 3px;
    padding-top: 3px;
  }

  .alarm-box .days {
    text-transform: capitalize;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    /* identical to box height, or 169% */


    /* Black n White/Black 50% */

    color: #8A90A0;
    text-align: left;
  }

  .switcher-main {
    position: absolute;
    right: 0;
    top: 26px;
  }

  .minWith {
    min-width: 700px;
    max-width: 700px;
  }

  button {
    text-transform: none !important;
  }

  .left-menu .MuiDrawer-paper {
    color: #FFFFFF;
    background: #152141;
  }

  .left-menu .title {
    margin-left: 30px;
    margin-top: 22px;

    /* hearding/H3 Bold (24-28) */

    font-family: Arial;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    /* identical to box height, or 117% */


    /* Black n White/White */

    color: #FFFFFF;
  }

  .MuiList-root .MuiListItemButton-root {

    padding: 0;
    // padding-top: 13px;
    height: 50px;


    padding: 13px 16px !important;
    margin-left: 14px !important;
    margin-right: 14px;
    border-radius: 9px;

  }

  .social-media {
    height: 40px;
  }

  .social-media a {
    text-decoration: none;
    font-family: Arial;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    /* identical to box height, or 160% */


    /* Black n White/Black 80% - paragraph */

    color: #444D67;
  }

  .social-media ul {
    padding: 0;
    margin: 0;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: space-between;

  }

  .social-media ul li {
    list-style-type: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
  }

  .social-media ul li:first-child a {
    display: flex;
  }

  .gray-circle {
    background: rgba(255, 255, 255, 0.6);
    border: 1px solid #B9BCC6 !important;
  }

  .circle,
  .MuiIconButton-edgeEnd {
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;

    width: 40px;
    height: 40px;

    /* Accent Green/Green Subtle */

    border: 1px solid rgb(3, 201, 215);
    border-radius: 20px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
  }

  .MuiIconButton-edgeEnd:hover {
    border: 1px solid #80C391;
    background: transparent;
  }



  .fullscreen-enabled {
    display: block;
    background: white;
    background-color: white;
  }

  .timer-container {
    width: 1012px;
    margin: auto;

    .common-times {
      justify-content: center;
      display: flex;
    }

    .key_select_type {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      label {
        margin-right: 0 !important;

        .MuiTypography-root {
          font-family: 'Arial';
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 24px;
          text-align: right;
          color: #444D67;
        }
      }
    }

    .fullscreen {
      width: 100%;
    }

    .fullscreen {
      .display-container {
        height: 100% !important;
        padding: 24px 28px;
      }
    }

    .available-timers {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 40px;
    }

    .progress-bar-info {
      width: 100%;
      margin-top: 20px;
    }

    .alarm-box {
      position: relative;

      .simple-display-operations {
        width: 50px;
        flex-direction: column;
        row-gap: 15px;
        position: absolute;
        top: 28px;
        right: 40px;
      }
    }

    .simple-display-operations {
      display: flex;
      // align-items: center;
      // justify-content: space-between;
      width: 100px;
    }

    .simple-display-operations-countdown {
      width: 200px;
      margin: auto;

      position: absolute;
      top: 0;
      right: 24px;
      display: flex;
      flex-direction: column;
      width: 40px;
      row-gap: 16px;
      top: 31px;
    }

    .share-button-div {
      //flex-direction: row-reverse;
    }

    .share-button-div-selected {
      padding: 8px;
      background: white;
      border-radius: 50px;
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease;
      width: 204px;
      position: absolute;
      right: 0;
      flex-direction: row;

    }

    .extra-field {

      display: flex;
      width: auto !important;
      align-items: center;
      position: relative;

      .Mui-disabled .MuiOutlinedInput-notchedOutline {
        border-color: #E8E9EC;
      }

      .MuiInputBase-formControl {
        width: 130px !important;
      }

      .MuiTextField-root {
        width: 75% !important;
      }

      .Mui-disabled {
        background-color: white !important;
      }
    }

    .digital_clock_style {
      font-family: 'Arial';
      font-style: normal;
      font-weight: 400;
      font-size: 60px;
      line-height: 60px;
      text-align: center;
      color: #152141;
      text-align: center;
      display: block;
    }

    .btn-div {
      justify-content: center;
      display: flex;
    }

    .key_set_timer {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100% !important;
    }

    .simple-select__control {
      //width: 100px;
    }

    .sub-title {
      h5 {
        font-family: 'Arial';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 22px;
        margin-top: 4px;
        margin-bottom: 4px;

        /* identical to box height, or 169% */


        /* Black n White/Black 50% */

        color: #8A90A0;

      }
    }

    .addTimer {
      width: 623px;
      margin: auto;

      .text-input-timer {
        width: 100%;

        .MuiInputBase-formControl {
          width: 100%;
          background: white !important;



          .Mui-disabled {
            background: white !important;
          }
        }
      }

      .flex-form {
        width: auto;
      }

      .flex-form .title {
        width: 30%;

        h5 {
          line-height: 0;
        }
      }

      .flex-form .field {
        width: 60%;
      }

      .timer-field {
        display: flex;
        justify-content: space-between;

        .MuiInputBase-formControl {
          padding: 14px 16px;
          width: 178px !important;
          height: 50px;
          // flex-direction: column;
          position: relative;

        }

        .flex-form {
          align-items: baseline;
        }

        .title {
          width: 100%;

          h5 {
            text-align: left !important;
            font-size: 15px !important;
            margin-top: 0;
          }

        }



        .timer-icon {
          margin-left: 13px;
        }

        /*.datepicker-input {
          .MuiIconButton-edgeEnd {
            position: absolute;
            right: -40px;

            bottom: 5px;
          }
        }*/


      }
    }
  }



  .countdown-container {

    .datepicker-input input:read-only {
      height: 50px;
    }

    .ColorPicker-MuiButton-contained:hover {
      box-shadow: none;
      background-color: inherit;
      background-image: none;
    }

    .ColorPicker-MuiButtonBase-root {
      width: 136px;
      height: 50px;
      margin: 0;
      /* Black n White/Black 50% */


      /* Black n White/Black 10% */

      border: 1px solid #E8E9EC;
      border-radius: 4px;

      .ColorPicker-MuiButton-label {
        div {
          width: 136px;
          height: 50px;
        }
      }
    }

    .bg-images {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: left;
      row-gap: 8px;

      img {
        border-radius: 4px;
      }
    }

    .left-side-content {
      .dropDown-div {
        top: 104px !important;
      }
    }

    .addTimer {
      width: 100%;

      padding-bottom: 0;
      display: flex;

      h3 {
        margin-top: 0;
        margin-bottom: 0;
      }

      .image-block {
        padding: 3px;
        height: 58px;
        border: 1px solid transparent;

        &:hover {
          cursor: pointer;
          border: 1px solid rgb(3, 201, 215);
          border-radius: 4px;
        }
      }

      .image-block-selected {
        cursor: pointer;
        border: 1px solid rgb(3, 201, 215);
        border-radius: 4px;
      }


      .right-side {
        // width: 70%;
        padding-left: 28px;
        display: flex;

        .simple-select__control {
          width: 300px;
        }

        .left-right-side {
          width: 300px;
        }


        .right-right-side {
          width: 300px;
          height: 404px;
          left: 684px;
          top: 0px;
          overflow-y: scroll;

          /* Black n White/White */

          background: #FFFFFF;
          /* Black n White/Black 20% */

          border: 1px solid #DADDE3;
          border-radius: 4px;
          padding: 16px;
          margin-left: 28px;

          &::-webkit-scrollbar {
            width: 10px;
          }

          &::-webkit-scrollbar-track {
            background: #E8E9EC;


          }

          &::-webkit-scrollbar-thumb {
            // background-color: darkgrey;
            background: rgb(3, 201, 215);
            border-radius: 10px;
            outline: 1px solid slategrey;
          }

          h5 {
            text-align: left;
            margin-bottom: 10px;
          }

          h4 {
            font-family: 'Arial';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 24px;
            color: #444D67;
            margin-bottom: 2px;
          }
        }
      }

      .left-side {
        width: 305px;
        border-right: 1px solid #DADDE3;
        display: flex;
        flex-direction: column;
        align-items: baseline;
        padding-right: 30px;

        .date-time {
          flex-direction: column;

          .MuiBox-root {
            overflow: auto !important;
          }

          .extra-field {
            justify-content: flex-start !important;
          }
        }



        .datepicker-input {
          input[type="text"] {
            width: 223px;

          }
        }
      }

      .MuiInputBase-formControl {
        width: 271px !important;
      }




      .text-input-timer {
        width: auto !important;
        margin-right: 7px;

        .MuiInputBase-formControl {
          width: 100%;
          background: white !important;

          .Mui-disabled {
            background: white !important;
            color: #152141;
            -webkit-text-fill-color: #152141
          }
        }
      }



      .flex-form {
        width: 100%;
      }

      .flex-form .title {
        width: 100%;
        margin-right: 0;

        h5 {
          text-align: left;
          line-height: 0;

          margin-bottom: 4px;
          margin-top: 16px;

        }
      }

      .flex-form .field {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .timer-field {
        display: flex;
        justify-content: space-between;

        .MuiInputBase-formControl {
          padding: 14px 16px;
          width: 125px;
          height: 50px;
          flex-direction: column;
          position: relative;

        }

        .flex-form {
          align-items: baseline;
        }

        .title {
          width: 100%;

          h5 {
            text-align: left !important;
            margin-bottom: 18px;
            margin-top: 0;
          }

        }



        .timer-icon {
          margin-left: 13px;
        }

        .datepicker-input {
          .MuiIconButton-edgeEnd {
            position: absolute;
            right: -40px;

            bottom: 5px;
          }
        }


      }
    }
  }

  .MuiTabs-scroller {
    overflow: scroll !important;
  }

  .circle:hover,
  .active-eco {
    cursor: pointer;
    border: 1px solid #80C391;
    background: transparent;
  }


  .footer .circle:hover {
    border: none !important;
  }

  .footer .social-media .twitter-class {
    background: #1095EF;
    border: none;
  }

  .footer .social-media .twitter-class:hover {
    background: #58B5F4;
  }


  .footer .social-media .whatsup-class {
    background: #31CE46;
    border: none;
  }

  .footer .social-media .whatsup-class:hover {
    background: #6EDD7D;
  }

  .footer .social-media .facebook-class {
    background: #4667B9;
    border: none;
  }

  .footer .social-media .facebook-class:hover {
    background: #6B85C7;
  }

  .footer .social-media .linkedin-class {
    background: #0085CC;
    border: none;
  }

  .footer .social-media .linkedin-class:hover {
    background: #0095E6;
  }

  .show-more-settings {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 180px !important;
  }

  .logo-div {
    text-decoration: none;

    h3 {
      color: rgb(3, 201, 215) !important;
    }
  }

  .show-more-settings:hover {
    cursor: pointer;
  }

  .MuiSnackbarContent-root {

    padding: 20px 80px;
    gap: 10px;


    width: 233px;
    height: 65px;


    /* Black n White/White */

    background: #FFFFFF;
    /* Shadow dark 10% */

    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }

  .MuiSnackbarContent-message {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    /* identical to box height, or 156% */

    text-align: center;

    /* Black n White/Main Black - heading */

    color: #152141;
    padding: 0;
  }



  .message-text {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    color: #152141;
    width: 100%;
  }

  .hot-key,
  .hot-key-keys {

    display: flex;
    flex-direction: row;
    align-items: center;

    .hot-key-title {
      margin-left: 15px;

    }

    .key {
      border: 1px solid #ccc;
      background: #ededed;

      padding-left: 5px;
      padding-right: 5px;
      border-radius: 4px;
    }

  }


  .show-more-settings-text {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    /* identical to box height, or 160% */


    /* Black n White/Black 80% - paragraph */

    color: #444D67;

  }

  .deleteButton {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    /* identical to box height, or 156% */


    /* Accent Green/Green Dark */

    color: #56A269;
  }

  .cancelButton {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    /* identical to box height, or 156% */


    /* Black n White/Black 80% - paragraph */

    color: #444D67;
  }

  .MuiBackdrop-root {
    background-color: #152141 !important;
    opacity: 0.6 !important;
  }



  .alarm-clock {
    margin-bottom: 20px;
  }

  .removeSign {
    position: absolute;
    right: 33px;
    top: 33px;
  }

  .extra-style-clock {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 52px;
    /* identical to box height, or 108% */

    text-align: center;

    /* Black n White/Main Black - heading */

    color: #152141;
  }

  /*social-media*/



  .social-media .twitter-class:hover {
    background: #1095EF;
  }

  .social-media .circle:hover {
    border: none;
  }

  .social-media .circle:hover svg path {
    fill: #FFFFFF;

  }




  .social-media .whatsup-class:hover {
    background: #31CE46;
  }



  .social-media .facebook-class:hover {
    background: #4667B9;
  }


  .social-media .linkedin-class:hover {
    background: #0085CC;
  }

  .MuiList-root .MuiListItemButton-root:hover {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 13px 16px;

    margin-left: 14px;
    margin-right: 14px;
    background: #283E7A;
    /* Shadow dark 7% */

    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07);
    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }

  .MuiListItemIcon-root {
    padding-right: 15px;
    min-width: auto;
  }

  .MuiListItemText-root span {
    /* hearding/H5 (18-24)+H3_mobile */

    font-family: Arial;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */


    /* Black n White/White */

    color: #FFFFFF;
  }

  .today-div {
    text-align: center;
    margin-bottom: 40px;
  }

  .right-side-today-div .digital_clock_style {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    /* identical to box height, or 130% */


    /* Black n White/Black 80% - paragraph */

    color: #444D67;
  }

  .right-side-today-div .digital_clock_style_date {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 18px;
    /* identical to box height, or 164% */


    /* Black n White/Black 30% */

    color: #B9BCC6;
    margin: auto;
  }

  .right-side-today-div {
    text-align: right;
    margin-top: -28px;
  }

  .main-div {
    margin-top: 28px;
    padding-left: 0;
    padding-right: 0;
  }

  .digital_clock_style_date {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 0;
    /* identical to box height, or 160% */


    /* Black n White/Black 80% - paragraph */

    color: #444D67;
  }

  .addAlarm {
    width: 1012px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 0px;

    background: #F8F8F9;
    border-radius: 4px;

    .flex-form {
      margin-top: 28px;
      //width: auto !important;
    }

    .timepicker-div {
      margin-top: 28px;
    }
  }

  h2 {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    width: 100%;
    margin: 0 auto;
    /* identical to box height, or 125% */

    text-align: center;

    /* Black n White/Main Black - heading */

    color: #152141;
  }

  .title {

    h5,
    h3 {
      font-family: 'Arial';
      font-style: normal;
      font-weight: 400;
      font-size: 18px !important;
      line-height: 24px !important;

      /* identical to box height, or 133% */

      text-align: right;

      /* Black n White/Main Black - heading */

      color: #152141;
      margin: auto;

      &::first-letter {
        text-transform: capitalize;
      }
    }
  }

  .top-header {
    background-color: white;
    box-shadow: none;
    border-radius: 0 !important;

  }



  .my-container {
    display: flex;
    justify-content: space-between;
    width: 1012px;
    margin: 0 auto;
    flex-wrap: wrap;
    row-gap: 24px;

    .MuiBox-root {
      overflow: unset !important;
    }

  }

  .my-switcher .react-switch-bg {

    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 22px;
    /* identical to box height, or 118% */

    text-transform: uppercase;

    /* Black n White/White */

    color: #FFFFFF;
  }

  .extra-for-display {
    margin-bottom: 14px;
  }

  .progress-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }

  .progress-bar-info {
    width: 220px;
  }

  .countdown-div {
    height: 26px;

    /* hearding/H4 (20-26)+H2_mobile */

    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    /* identical to box height, or 130% */


    /* Black n White/Black 50% */

    color: #8A90A0;
    text-align: left;

  }

  .MuiLinearProgress-root {
    height: 6px;
    /* Black n White/Black 10% */

    background: #E8E9EC;
    border-radius: 10px;
  }

  .MuiLinearProgress-bar {
    /* Accent Green/Main green */

    background: rgb(3, 201, 215);
    border-radius: 10px;
  }

  h4 {
    /* hearding/H4 (20-26)+H2_mobile */

    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    margin: 0 auto;
    /* identical to box height, or 130% */


    /* Black n White/Black 80% - paragraph */
    text-align: left;
    margin-bottom: 12px;
    text-transform: capitalize;
    color: #444D67;
  }

  .switcher-true {
    margin-left: 8px;
  }


  .trashDialog .MuiDialog-paper {
    width: 491px;

    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgb(0 0 0 / 10%);
    border-radius: 4px;
    height: 220px;
  }

  .editDialog .MuiDialog-paper {
    max-width: 850px;

    .dropDown-div {
      left: -245px;
    }
  }

  .countdown-container-dialog {

    .coundown-default {

      margin-top: 90px;
    }

    .display-container {
      margin-top: 140px;
      flex-wrap: inherit;
    }

    .MuiDialog-paper {
      width: 1112px;
      max-width: 1112px;
      margin: 0;
    }
  }

  .editdialogclass {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  .timer-started-info {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    text-align: left;
    color: #152141;
    flex-direction: column;
    align-items: flex-start;

    .title-span {
      font-family: 'Arial';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 22px;
      /* identical to box height, or 169% */


      /* Black n White/Black 80% - paragraph */

      color: #444D67;
    }


  }

  .trashDialog .MuiDialogContent-root {
    align-items: center;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
  }

  .embed-div .MuiDialogTitle-root {
    /* H3 (32-40) */


    font-family: 'Arial' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 32px !important;
    line-height: 40px !important;
    color: #152141 !important;
    margin-top: 18px !important;
    text-align: left !important;
  }

  .embed-div .MuiDialog-paper {
    width: 740px !important;
    height: 793px !important;
    max-height: 793px !important;
  }

  .alarmDialog .btn-div button {
    padding: 13px 28px;


    min-width: 156px !important;
    height: 50px !important;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    text-transform: uppercase;
  }

  .days-text {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    color: #152141;
    text-transform: capitalize;
  }

  .alarmDialog .MuiDialogActions-root {
    margin-top: 20px;
  }


  .MuiDialogTitle-root+.css-ypiqx9-MuiDialogContent-root {
    padding-top: 5px;
  }

  .alarmDialog .MuiDialog-paper {
    width: 660px;
    height: 432px;


    background: #FFFFFF;
    /* Shadow dark 10% */

    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    position: relative;
  }

  .alarmDialog .MuiDialogTitle-root {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    /* identical to box height, or 130% */

    text-align: center;

    /* blue hover (sidebar) */

    color: #283E7A;
    margin-top: 80px;
    padding-bottom: 8px;
  }

  .alarmDialog .MuiDialogContent-root {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

}

.disabled-btn-div button {

  /* Subtitle (16-20) buttons */

  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  text-transform: uppercase;

  /* Black n White/White */

  color: #FFFFFF;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  align-items: center;
  padding: 15px 28px;
  gap: 10px;



  /* Black n White/Black 30% */

  background: #B9BCC6 !important;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.settings-countdown-title {
  text-align: left;
}

.countdown-2-start {
  padding: 10px;
  grid-gap: 10px;
  gap: 10px;
  border: 1px solid rgb(3, 201, 215);
  border-radius: 25px;
}

.autocomplete-container {
  position: relative;
}

.autocomplete-dropdown-container {
  background: #FFFFFF;
  border: 1px solid rgb(3, 201, 215);
  box-shadow: 0px 4px 10px rgb(0 0 0 / 5%);
  border-radius: 4px;
  max-height: 165px;
  top: -8px;
  margin-top: -5px;
  overflow-y: scroll;

  .suggestion-item {
    font-size: 15px;
    line-height: 17px;
    margin-top: 6px;
    /* identical to box height, or 113% */


    /* Black n White/Main Black - heading */

    color: #152141;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 16px;
    text-transform: capitalize;
  }



  .suggestion-item:first-child {
    margin-top: 8px;
  }

  &::-webkit-scrollbar {
    width: 14px !important;
    height: 12px !important;
  }

  &::-webkit-scrollbar-track {

    background: #E8E9EC;
    border-radius: 10px;
    transform: rotate(90deg);
  }

  &::-webkit-scrollbar-thumb {
    height: 12px !important;
    width: 14px !important;
    background: rgb(3, 201, 215);
    border-radius: 10px;
    transform: rotate(90deg);
  }

  &::-webkit-scrollbar-thumb:hover {

    background: rgb(3, 201, 215);
    border-radius: 10px;
    transform: rotate(90deg);
  }
}

.stopwatch-container {


  .right-operations {
    .circle-container {
      .title {
        display: none;
      }
    }
  }

  .history-list {
    td.flex-div {
      display: none;
    }

    td {
      padding-bottom: 5px;
    }

    .time-displayed {
      font-family: 'Arial';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 22px;
      /* identical to box height, or 169% */

      text-align: right;

      /* Black n White/Black 80% - paragraph */

      color: #444D67;
    }

    .MuiInputBase-formControl {
      height: 40px;
    }
  }

  .edit-laps {
    .MuiFormControlLabel-label {
      /* body/body bold (16-25) */

      font-family: 'Arial';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 25px;
      /* identical to box height, or 156% */


      /* Black n White/Main Black - heading */

      color: #152141;
      text-transform: capitalize;
    }
  }

  .display-operations {
    width: 290px;
    margin: auto;
    justify-content: space-between;
    margin-top: 28px;
  }
}


.stopwatch-container,
.timer-container,
.currenttime-container {


  .MuiToggleButtonGroup-root {
    button {
      height: 22px;
      font-family: 'Arial';
      font-style: normal;
      font-weight: 700;
      font-size: 11px;
      line-height: 18px;
      /* identical to box height, or 164% */


      /* Black n White/Black 30% */

      color: #B9BCC6;

    }

    button:last-child {
      border-radius: 0px 20px 20px 0px;
    }

    button:first-child {
      border-radius: 20px 0px 0px 20px;
    }


    .Mui-selected {
      background: rgb(3, 201, 215);

      color: white;
    }
  }

  .add-locations {
    .btn-div {
      width: 196px;
      height: 50px;
    }

    .add-locations-info {
      font-family: 'Arial';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 22px;
      margin-top: 12px;
      /* identical to box height, or 169% */


      /* Black n White/Black 50% */

      color: #8A90A0;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-top: 28px;
  }

  .analog-clock-container {
    .clock {
      width: 172px;
      height: 172px;
      border-radius: 50%;
      // position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(0%, 0%);
      border: 2px solid #283E7A;
      font-size: 24px;
      color: #444;
      text-align: center;
    }

    .clock::after {
      background: #aaa;
      content: "";
      width: 6px;
      height: 6px;
      border-radius: 50%;
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #283E7A;
    }

    .hour_hand {
      position: absolute;
      width: 2px;
      height: 35px;
      background: #283E7A;
      top: 30%;
      left: 49%;
      transform-origin: bottom;
    }

    .min_hand {
      position: absolute;
      width: 2px;
      height: 50px;
      background: #283E7A;
      top: 20.5%;
      left: 49%;
      transform-origin: bottom;
    }

    .sec_hand {
      position: absolute;
      width: 1px;
      height: 68px;
      background: #F98443;
      top: 9.5%;
      left: 50%;
      transform-origin: bottom;
    }

    .clock span {
      position: absolute;
      font-family: 'Arial';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      /* identical to box height, or 160% */


      /* Black n White/Black 80% - paragraph */

      color: #444D67;
    }

    .twelve {
      top: 0px;
      left: 44%;
    }

    .one {
      top: 12.14px;
      right: 43px;
    }

    .eleven {
      top: 12.14px;
      left: 38px;
    }

    .two {
      top: 38.86px;
      right: 20.39px;
    }

    .three {
      right: 11.4px;
      top: 74.15px;
    }

    .four {
      right: 20.4px;
      top: 109.22px;
    }

    .five {
      right: 43px;
      top: 137px;
    }

    .six {
      bottom: 0px;
      left: 81px;
    }

    .seven {
      left: 43px;
      top: 137px;
    }

    .eight {
      left: 20.71px;
      top: 109.22px;
    }

    .nine {
      left: 11.47px;
      top: 74.15px;
    }

    .ten {
      top: 38.08px;
      left: 17px;
    }

  }

  .customize-container {
    width: 1012px;

    margin: auto;
    margin-bottom: 16px;
    margin-top: -8px;

    .operation-button {

      display: flex;
      align-items: center;
      justify-content: flex-end;
    }


    .title {
      font-family: 'Arial';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 25px;
      /* identical to box height, or 156% */


      /* Black n White/Black 80% - paragraph */

      color: #444D67;
      margin-right: 8px;
    }
  }

  .location-add-container {
    width: 1012px;
    position: relative;
    background: #F8F8F9;
    padding-left: 28px;
    padding-right: 28px;
    padding-top: 24px;
    padding-bottom: 24px;
    border-radius: 4px;
    margin: auto;
    margin-bottom: 24px;

    .close-popup {
      position: absolute;
      right: 28px;
      top: 24px;
    }

    .location-add-container-header {
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .title {
        font-family: 'Arial';
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 24px;
        /* identical to box height, or 160% */


        /* Black n White/Main Black - heading */

        color: #152141;
      }
    }

    .close-popup {
      // position: absolute;
    }

    .search {
      position: absolute;
      top: 16px;
      left: 19px;
    }

    .location-search-input {
      width: 956px;
      height: 50px;
      background: #FFFFFF;
      border: 1px solid #E8E9EC;
      border-radius: 4px;
      padding-left: 44px;

      &:focus-visible {
        outline: none;
      }

      &:focus {
        outline: 1px solid rgb(3, 201, 215);
      }
    }


    .location-search-input::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      font-family: 'Arial';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      /* identical to box height, or 160% */


      /* Black n White/Black 50% */

      color: #8A90A0;
      /* Firefox */
    }

    .location-search-input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      font-family: 'Arial';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      /* identical to box height, or 160% */


      /* Black n White/Black 50% */

      color: #8A90A0;
    }

    .location-search-input::-ms-input-placeholder {
      /* Microsoft Edge */
      font-family: 'Arial';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      /* identical to box height, or 160% */


      /* Black n White/Black 50% */

      color: #8A90A0;
    }
  }

  .info-block-each {
    background: transparent !important;
    //box-shadow: none;
  }

  .lap-history {
    background: #F8F8F9;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 28px;
    border-radius: 4px;

    .Mui-checked {
      color: rgb(3, 201, 215) !important;
    }
  }

  .time {
    .countdown-div {
      font-family: 'Arial';
      font-style: normal;
      font-weight: 400;
      font-size: 48px;
      line-height: 52px;
      text-align: center;
      color: #152141;
      margin-bottom: 20px;
      font-size: 60px !important;
      line-height: 60px !important;
    }
  }

  .d-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }

  .d-flex-raw {
    display: flex;
    justify-content: center;

  }

  .alarm-box {
    width: 48%;
  }

  .timer-btn-info {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    color: #8A90A0;
    margin-top: 28px;
    margin-bottom: 12px;
  }

  .trans-bg {
    background: transparent !important;
    color: #56A269 !important;
    height: 40px !important;

    &:hover {
      border: 1px solid rgb(3, 201, 215);
    }
  }

  .sub-operations {
    margin-top: 28px;
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      width: 110px;
    }
  }

  .operation-title {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin-top: 28px;
    margin-bottom: 15px;
    /* identical to box height, or 133% */

    text-align: center;

    /* Black n White/Main Black - heading */

    color: #152141;

  }

  .common-times [role="group"] button {
    border-radius: 50px;
    margin-right: 12px;
    border: 0;
    border-top-left-radius: 50px !important;
    border-top-right-radius: 50px !important;
    border-bottom-left-radius: 50px !important;
    border-bottom-right-radius: 50px !important;
    text-transform: capitalize !important;
    margin-bottom: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    justify-content: center;
    align-items: center;
    padding: 9px 12px;
    grid-gap: 10px;
    gap: 10px;
    text-transform: uppercase !important;
    height: 34px;
    background: #F3F4F6;
    font-family: "Arial";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    color: #8A90A0;
    /* Black n White/White */
    width: 85px;
    height: 34px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;

    background: #FFFFFF;
    /* Accent Green/Green Subtle */

    border: 1px solid rgb(3, 201, 215);
  }

  .common-times [role="group"] .Mui-selected {
    background: rgb(3, 201, 215) !important;
    color: #152141 !important;
  }

  .my-container {
    // width: 100%;

    .info-block-each {
      margin-bottom: 30px;
    }
  }

  .last-lap-container {

    background: #F8F8F9;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 28px;
    border-radius: 4px;

    .lap-inner {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      justify-content: space-around;
      padding-top: 20px;
      padding-bottom: 20px;

      .gray-display {
        /* H3 (32-40) */

        font-family: 'Arial';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 40px;
        /* identical to box height, or 125% */

        text-align: center;

        /* Black n White/Black 50% */

        color: #8A90A0;
      }

      .lap-sub-title {
        font-family: 'Arial';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 25px;
        /* identical to box height, or 156% */

        text-align: center;

        /* Black n White/Main Black - heading */

        color: #152141;
      }

      .lap-comment,
      .lap-time {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .comment-input {
          margin-left: 12px;
        }
      }
    }
  }

  .stopwatch-history-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #F8F8F9;
    border-radius: 4px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;

    .history-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    .history-list {
      width: 100%;

      table {
        .middle-td {
          // width: 20%;
          text-align: left;
        }

        tr {
          height: 40px;

          th {
            text-align: left;
            /* body/body bold (16-25) */

            font-family: 'Arial';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 25px;
            /* identical to box height, or 156% */


            /* Black n White/Main Black - heading */

            color: #152141;
          }

          td {

            .stopwatch__display {
              text-align: left;
              /* hearding/H4 (20-26)+H2_mobile */

              font-family: 'Arial';
              font-style: normal;
              font-weight: 400;
              font-size: 20px;
              line-height: 26px;


              /* Black n White/Main Black - heading */

              color: #152141;
            }

            .max-index {
              .stopwatch__display {
                color: #F8691A;
              }
            }

            .min-index {
              .stopwatch__display {
                color: #56A269;
              }
            }
          }
        }

        width: 100%;
      }
    }
  }

  .preview-container-middle {
    width: 491px;
    justify-content: flex-start !important;
    padding-top: 0 !important;
    height: 226px !important;

    .digital_clock_style {
      font-family: 'Arial';
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 40px;
    }

    .preview-container-content {
      margin-top: 28px;
    }

    .title {
      font-size: 24px !important;
      font-family: 'Arial';
      font-style: normal;
      font-weight: 400;
      margin-bottom: 8px;
      line-height: 28px;
    }

    .share-button-div-selected {
      position: static;
      margin-top: -8px;

    }

    .simple-display-operations-countdown {
      position: static;
      flex-direction: row;
      align-items: center;
      column-gap: 16px;
      width: auto;

      justify-content: center;
      margin-top: 20px;
    }

    .date {
      margin-top: 8px !important;
    }
  }

  .bg-div-cover {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover !important;
    width: 100%;
    height: 100%;
    z-index: 0;
    position: absolute;
    border-radius: 4px;
  }

  .preview-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 28px 0px;
    height: 270px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover !important;


    /* Black n White/White */

    background: #FFFFFF;
    /* Shadow dark 7% */

    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07);
    border-radius: 4px;
    margin-bottom: 28px;

    .preview-container-content {
      z-index: 1;

      .share-btns {
        height: 40px;
        width: 140px;
        display: flex;
        margin-right: 12px;
        justify-content: space-between;
      }
    }

    .date {
      font-family: 'Arial';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 26px;
      margin-top: 24px;
      text-align: center;
      /* identical to box height, or 130% */


      /* Black n White/Black 50% */

      color: #8A90A0;
    }

    .days-span {
      /* hearding/H2 (32-40) */

      font-family: 'Arial';
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 40px;
      /* identical to box height, or 125% */

      text-align: center;

      /* Black n White/Main Black - heading */

      color: #152141;
      text-transform: capitalize;
      margin-left: 15px;
      margin-right: 20px;
    }

    .title {
      font-family: 'Arial';
      font-style: normal;
      text-align: center !important;
      font-weight: 400;
      font-size: 32px;
      line-height: 40px;
      /* identical to box height, or 125% */

      text-align: right;

      /* Black n White/Black 50% */

      color: #8A90A0;
    }

  }



  .countdown-container {
    .display-container {
      row-gap: 24px;
    }

    .date-time {
      margin-bottom: 12px;

      .dropDown-div {
        top: 95px;

      }

      display: flex;
      width: 100%;

      .MuiBox-root {
        overflow: auto !important;

        input[type="text"] {
          padding: 13px 16px;
          grid-gap: 10px;
          gap: 10px;
          width: 130px;
          height: 50px;
          border: 1px solid #E8E9EC;
          border-radius: 4px;
          color: #152141;
          //color: rgba(0, 0, 0, 0.38);
          font-size: 16px;
        }
      }
    }

    .coundown-default {
      min-width: 386px;

      input[type="text"] {
        font-size: 15px !important;
        color: #444D67 !important;
      }

      .flex-form {
        flex-direction: column;
        align-items: baseline;
        width: 100%;

        .field {
          width: 100%;

          .text-input-timer {
            width: 100%;

            .MuiOutlinedInput-root {
              width: 100%;
            }
          }
        }

        .title {
          text-align: left;
          width: 100%;
          margin-right: 0;

          h5 {
            text-align: left;
            margin-bottom: 2px;
            //text-transform: capitalize;
            font-family: 'Arial';
            font-style: normal;
            font-weight: 400;
            font-size: 13px !important;
            line-height: 22px !important;
            /* identical to box height, or 169% */


            /* Black n White/Black 50% */

            color: #8A90A0;

            &::first-letter {
              text-transform: uppercase;
            }


          }
        }
      }
    }

    .right-operations {
      position: absolute;
      right: 15px;
      z-index: 4;
      top: 135px;

      .circle {
        margin-bottom: 11px;
      }
    }
  }

  .display-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 28px 0px;
    gap: 30px;
    /* position: absolute; */
    width: 100%;
    height: 252px;
    /* left: 238px; */
    /* top: 112px; */
    background: #F8F8F9;
    border-radius: 4px;
    position: relative;



    .display-operations {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 18px;

      button {
        width: 130px;
      }

      .active-lap {
        button {
          background: rgb(251, 150, 120);
        }

      }
    }

    .right-operations {
      position: absolute;
      right: 40px;

      .circle {
        margin-bottom: 11px;
      }
    }

    .stopwatch__display {
      font-family: 'Arial';
      font-style: normal;
      font-weight: 400;
      font-size: 60px;
      line-height: 100px;
      /* identical to box height, or 100% */
      text-align: center;
      /* Black n White/Main Black - heading */

      color: #152141;


      /* Inside auto layout */

      flex: none;
      order: 0;
      flex-grow: 0;

      span {
        width: 110px !important;
      }

      .span_display {


        /* H2 (48-52) */

        font-family: 'Arial';
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 52px;
        /* identical to box height, or 108% */
        width: 70px !important;
        text-align: center;

        /* Black n White/Main Black - heading */

        color: #152141;
      }
    }
  }
}

.stopwatch-container {
  .display-container {
    width: 1012px;
  }
}


.time-display-container {
  width: 100%;
  position: relative;

  .simple-display-operations {
    position: absolute;
    right: 0;
    top: 0;

    .edit-options {
      width: 251px;
      height: 158px;
      padding: 24px;
      background: #FFFFFF;
      /* Shadow dark 10% */

      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
      border-radius: 4px;

      .edit-options-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .edit-options-content {
        margin-top: 20px;

        label:first-child {
          margin-bottom: 20px;
        }

        label {
          margin-left: 0;

          .MuiTypography-root {
            font-family: 'Arial';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 24px;
            color: #444D67;
          }

          .MuiCheckbox-root {
            padding: 0;
            width: 24px;
            margin-right: 8px;

          }
        }
      }
    }


  }

  .title {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;

    /* identical to box height, or 130% */

    text-align: center;

    /* Black n White/Main Black - heading */

    color: #152141;
  }

  .time-content {
    height: 100px;
    margin-top: 28px;
    margin-bottom: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .digital_clock_style {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 100px;
    line-height: 80px;

    /* identical to box height, or 100% */

    text-align: center;

    /* Black n White/Main Black - heading */

    color: #152141;
  }

  .time-info {
    .current-date {
      font-family: 'Arial';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;

      /* identical to box height, or 160% */

      text-align: center;

      /* Black n White/Main Black - heading */

      color: #152141;
    }

    .current-timezone {
      font-family: 'Arial';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;

      /* identical to box height, or 160% */

      text-align: center;

      /* Black n White/Black 50% */

      color: #8A90A0;
    }

    .current-time-difference {
      font-family: 'Arial';
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 22px;
      color: #152141;
    }
  }
}

.display-multiple {
  .time-display-container {
    padding: 28px 40px;


    width: 491px;
    // height: 206px;
    max-height: 228px;

    /* Black n White/White */

    background: #FFFFFF;
    /* Shadow dark 7% */

    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07);
    border-radius: 4px;
    margin-bottom: 20px;

    .display-multiplied-true {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: calc(100% - 56px);
      justify-content: space-between;
    }

    .simple-display-operations {
      right: 24px;
      top: 24px;

      .home-icon:hover {
        cursor: pointer;

        svg {
          path {
            fill: rgb(3, 201, 215);
          }

        }
      }
    }

    .title {
      text-align: left;
    }

    .time-content {
      justify-content: flex-start;
      margin-top: 12px;
      margin-bottom: 12px;
      height: auto;
    }

    .class-analog {
      margin-top: 0;
      margin-bottom: 0;
      align-items: flex-start;

      .current-date {
        margin-top: 12px;
      }

      .analog-clock-container {
        margin-right: 24px;
      }
    }

    .time-info {

      .current-timezone,
      .current-date {
        text-align: left;
      }
    }

    .digital_clock_style {
      font-family: 'Arial';
      font-style: normal;
      font-weight: 400;
      font-size: 48px;
      line-height: 52px;
      /* identical to box height, or 108% */


      /* Black n White/Main Black - heading */

      color: #152141;
      text-align: left;
    }
  }
}

.title-cities {
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  color: #152141;
  width: 1012px;
  margin: auto;
  margin-bottom: 24px;
}

.loading {
  position: absolute;
  top: 0;
  z-index: 6;
  width: calc(100% - 54px);
  height: 100%;
  /* right: -4%; */
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.currenttime-container {


  .display-container {
    width: 1012px;
    height: 290px;
    padding: 28px;

    .time-display-container {
      .time-content {
        height: auto;
      }

      .time-info {
        margin-left: 24px;
      }
    }
  }
}

.countdown-container {
  .preview-container {
    // height: 190px;

    .preview-container-content {
      .title {
        font-family: 'Arial';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        /* identical to box height, or 133% */

        text-align: right;

        /* Black n White/Black 50% */

        color: #8A90A0;

      }

      .date {
        font-family: 'Arial';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        margin-top: 8px;
        /* identical to box height, or 160% */


        /* Black n White/Black 50% */

        color: #8A90A0;
      }
    }

    .digital_clock_style {
      font-family: 'Arial';
      font-style: normal;
      font-weight: 400;
      font-size: 48px;
      line-height: 52px;
      /* identical to box height, or 108% */

      text-align: center;

      /* Black n White/Main Black - heading */

      color: #152141;
    }
  }


}

.tools-app {
  .countdown-container {
    .extra-field {

      width: 100% !important;

    }
  }
}

/* {
  outline: 1px solid red;
}*/

@media only screen and (max-width: 1190px) {
  .info-block {
    justify-content: center !important;
    row-gap: 24px !important;
    column-gap: 19px !important;
  }
}

@media only screen and (max-width: 955px) {

  .toglelike {
    flex-wrap: wrap;
  }

  .tools-app {
    .share-div {
      display: none;
    }

    .embed {
      display: none;
    }

    .stopwatch-container {
      .display-container {
        height: 274px;
        row-gap: 0;

        flex-wrap: nowrap;

        .stopwatch__display {
          font-size: 80px;
          line-height: 80px;
        }
      }
    }

    .stopwatch-container .display-container .right-operations {
      bottom: 0;
      display: flex;
      flex-direction: row;
      column-gap: 20px;
      position: static;
      justify-content: space-around;

      div[title="key_following_hot_keys"] {
        display: none;
      }

      .circle {
        margin-bottom: 0;

      }

      .circle-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row-reverse;

        .title {
          font-family: 'Arial';
          font-style: normal;
          font-weight: 400;
          font-size: 11px;
          line-height: 18px;
          margin-left: 4px;
          display: block;
          /* identical to box height, or 164% */

          text-align: right;

          /* Black n White/Black 80% - paragraph */

          color: #444D67;
          min-width: 60px;
        }
      }
    }

    .lap-inner {
      flex-wrap: wrap;
      justify-content: space-between !important;
      row-gap: 14px;
      padding-left: 15px;
      padding-right: 15px;


      .lap-index {
        width: 30%;
      }

      .lap-time {
        width: 65%;

      }

      .lap-comment .comment-input {
        .field {
          width: 100%;

          .text-input {
            width: 100%;
          }
        }
      }

      .lap-comment {
        .lap-sub-title {
          width: 30%;
          text-align: left;
        }
      }

      .comment-input {
        width: 65%;
        margin-left: 0 !important;
      }

      .lap-comment {
        width: 100%;
      }
    }
  }

  .timer-container {


    .sub-operations {
      button {
        width: 88px;
        height: 40px;
      }
    }

    .progress-bar-info {
      margin-top: 12px !important;
    }

    .simple-select__control {
      width: 94px !important;
    }



    .addTimer {
      padding-left: 15px;
      padding-right: 15px;



      .flex-form {
        // align-items: flex-start;
        // flex-direction: column;


        .field {
          // width: 50% !important;
        }


        .for-timer-title,
        .title {
          // width: 100%;
          //margin-bottom: 16px;

          h5 {
            text-align: left;

          }
        }

        .title {
          width: 161px;

          margin-right: 20px;

          h5 {
            text-align: right;
          }
        }
      }

      .key_set_timer_div {
        .title {
          display: none;
        }

        .key_set_timer {
          width: 100% !important;
          flex-wrap: wrap;
          row-gap: 20px;
        }
      }
    }
  }


}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 1055px) {
  .tools-app {

    .info-block {
      width: 100%;

    }

    .last-lap-container,
    .lap-history {
      width: 100%;
    }

    .timer-container {
      .available-timers {
        .alarm-box {
          height: 260px;
        }


        .simple-display-operations {
          position: absolute;
          bottom: 20px;
          right: 28px;
          bottom: 15px;
          right: 28px;
          flex-direction: row !important;
          column-gap: 20px;
          height: 40px;
          top: auto !important;
          width: 100px !important;


        }

        .sub-operations-div {
          .btn-div {
            button {
              height: 40px;
            }
          }
        }
      }

      .time {
        line-height: 28px;

        .countdown-div {
          margin-bottom: 0;
          font-family: 'Arial';
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 28px;
          /* identical to box height, or 117% */

          text-align: center;

          /* Black n White/Main Black - heading */

          color: #152141;
        }
      }

      .common-times {
        width: 100%;

        .MuiToggleButtonGroup-root {
          display: flex;
          width: 100%;
          align-items: center;
          flex-wrap: wrap;
          justify-content: center;
        }
      }
    }

    .countdown-container-main {


      .addTimer {
        width: 100%;
        flex-direction: column;
        padding: 24px;

        .left-side .datepicker-input input[type=text],
        .extra-field .MuiInputBase-formControl {
          width: 178px !important;
        }

        .left-side-content {
          .date-time {
            width: 50%;
            margin-right: 28px;
          }

        }



        .MuiInputBase-formControl {
          width: 226px !important;
        }

        .text-input-timer .MuiInputBase-formControl {
          width: 100% !important;
        }

        .left-side-content {
          display: flex;
          width: 100%;
        }

        .right-side .simple-select__control {
          width: 226px;
        }

        .left-side {
          border: none;
        }

        .left-side,
        .right-side {
          width: 100%;
          padding: 0;
        }
      }

      .preview-container-middle {
        width: 100%;

        .simple-display-operations-countdown {
          width: 100%;
          position: static;
        }
      }
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 1280px) {




  .my-container,
  .addAlarm {
    width: 100% !important;
  }

  .tools-app {

    .timer-container {
      width: auto;
    }

    .top-header {
      width: calc(100% - 208px);
    }
  }

  .main-component {
    margin-left: 0 !important;
  }

  .left-menu {
    width: 208px !important;

    .MuiListItemText-root span {
      font-size: 15px;
    }

    .MuiDrawer-paper {
      width: 208px !important;

    }
  }
}



/* Small devices (portrait tablets and large phones, 600px and up) */


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .embed {
    display: none;
  }

  .etc-span {
    display: block;
  }

  .title-cities {
    display: inline-flex;

    b {
      display: none;
    }

    b:first-child {
      display: block;
    }
  }

  .MuiListItemIcon-root {

    padding-right: 5px !important;
  }

  .left-menu {
    width: 181px !important;


    .MuiListItemText-root span {
      font-size: 15px;
    }

    .MuiDrawer-paper {
      width: 181px !important;

    }
  }

  .MuiList-root .MuiListItemButton-root {
    margin-left: 5px;
  }

  .bg-big,
  .bg-small {
    display: none;
  }

  .bg-middle {
    display: block;
  }

  .tools-app {

    .d-flex-raw {
      flex-direction: column;
    }

    .MuiListItemText-root span {
      font-size: 13px;
    }



    .left-menu {
      .title {
        font-size: 20px;
      }


    }

    .addAlarm {
      width: auto !important;
      margin-left: 20px !important;
      margin-right: 20px !important;

      .flex-form {
        width: 100%;
        padding-left: 29px;

        .title {
          margin-right: 20px;
          width: 30%;
        }
      }


    }

    .timepicker-div .field .MuiFormControl-root .MuiInputBase-formControl {
      height: 60px;
    }

    .text-input .MuiInputBase-formControl {
      width: 100%;
    }

    .my-container {
      width: auto !important;
      margin-left: 20px !important;
      margin-right: 20px !important;
    }

    .info-block-each,
    .alarm-box {
      width: 100%;
      margin-bottom: 20px;
    }

    .alarm-box {
      margin-left: 20px;
      margin-right: 20px;
    }

    .small-ad,
    .md-ad {
      width: 468px !important;
      height: 60px !important;
    }

    .switcher-main {
      top: -31px;
    }

    .embed-div {
      .switcher-main {
        top: 10px;
      }
    }

    .right-side-today-div {
      margin-right: 25px;
    }


    .bg-ad {
      width: 336px !important;
      height: 280px !important;
    }

    .info-block-each-text {
      width: 100%;
    }

    .rating-container,
    .location-add-container {
      width: auto !important;
    }

    .timer-container {
      .right-operations {
        display: none;
      }

      .common-times {
        width: auto;
        margin: auto;
        margin-bottom: 28px;

        .MuiToggleButtonGroup-root {


          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
        }
      }

      .available-timers {
        .simple-display-operations {
          position: absolute;
          bottom: 20px;
          right: 28px;
        }

        .alarm-box {
          padding-left: 28px;
          padding-right: 28px;
          position: relative;
          height: 228px;

          .time {
            width: 100%;
          }
        }

        .sub-operations-div {
          margin-top: 12px !important;
          justify-content: center !important;

        }

        .sub-operations {
          button {
            width: 88px;
            height: 40px;
          }
        }

        .countdown-div {
          font-family: 'Arial';
          font-style: normal;
          font-weight: 400;
          font-size: 32px;
          line-height: 40px;
          text-align: center;
          color: #152141;
          margin-bottom: 0;
          height: auto;
        }
      }
    }


    .stopwatch-container {

      .key_comments,
      .key_time_recorded {
        display: none;
      }

      .gray-display {
        font-family: 'Arial';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
        text-align: center;
        color: #8A90A0;

        .span_display {
          display: none;
        }
      }

      .display-operations {
        margin-top: 24px;
      }

      .stopwatch__display {
        font-family: 'Arial';
        font-style: normal;
        font-weight: 400;
        font-size: 80px;
        line-height: 80px;
        text-align: center;
        color: #152141;

        .span_display {
          font-family: 'Arial';
          font-style: normal;
          font-weight: 400;
          font-size: 32px;
          line-height: 40px;
          text-align: center;
          color: #152141;
          width: 75px !important;
        }
      }

      .history-list {
        .span_display {
          display: none;
        }


      }

      .right-operations {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 232px;
        bottom: 28px;
        height: 40px;
        left: 145.5px;

        .circle {
          margin-bottom: 0;
        }
      }
    }

    .location-add-container .location-search-input {
      width: 470px;
    }


    .display-multiple .time-display-container {
      width: 100%;
    }


    .main-component {
      margin-left: 168px;
      padding-left: 0;
      padding-right: 0;

    }

    .footer {
      .footer-container-inner {
        flex-direction: column-reverse;
        justify-content: center;
      }

      .social-media {
        width: 272px;
        margin-bottom: 20px;
        margin-left: 20px;

        ul {
          li {
            margin-right: 0;
          }
        }
      }

      .embed-btn-div {
        display: none;
      }
    }

    .top-header {
      width: calc(100% - 181px);
    }

    .MuiToolbar-root {
      //padding-left: 0;
      //padding-right: 0;
      margin-right: 20px;
    }



    //#Timer container
    .timer-container {
      width: auto;

      .image-block,
      .image-block .ColorPicker-MuiButtonBase-root,
      .image-block .ColorPicker-MuiButton-label div {
        width: 91px !important;
      }

      .image-block-selected {
        width: 100px !important;

        .title {
          h5 {
            margin-top: 0;
          }

        }
      }

      .right-right-side {
        padding: 9px !important;
      }

      .ColorPicker-MuiButtonBase-root {
        width: 107px;
        height: 50px;
        margin: 0;
        /* Black n White/Black 50% */


        /* Black n White/Black 10% */

        border: 1px solid #E8E9EC;
        border-radius: 4px;

        .ColorPicker-MuiButton-label {
          div {
            width: 107px;
            height: 50px;
          }
        }
      }

      .digital_clock_style {
        font-size: 32px;
        line-height: 40px;
      }

      .preview-container-middle,
      .fullscreen {
        width: 100%;
      }



    }

    .countdown-container {
      align-items: flex-start !important;
    }
  }




}

@media only screen and (max-width:600px) {
  .tools-app {
    .top-header {
      width: 100%;
    }

    .title {

      h5,
      h3 {
        text-align: left !important;
      }
    }

    header {
      button {
        position: absolute;
        right: 0;
        margin-right: 0;

        svg {
          path {
            fill: #444D67;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 391px) {
  .embed {
    display: none;
  }

  .timer-container .key_select_type {
    align-items: normal !important;
    flex-direction: column;
  }

  .stopwatch-container .display-container .stopwatch__display .span_display {
    width: auto !important;
  }

  .stopwatch-container .display-container .right-operations {
    top: 10px;
    left: 0;
    display: flex;
    justify-content: space-around;
    width: 100%;

    .key_fullscreen {
      display: none !important;
    }
  }

  .timer-container .sub-operations {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .sound-container {
    .simple-select__control {
      width: 178px !important;
    }

    margin-bottom: 20px;
  }



  .last-lap-container,
  .lap-history {
    display: none !important;
  }

  .stopwatch__display {
    font-size: 60px !important;

    .span_display {
      font-size: 20px !important;
    }
  }

  .tools-app {
    .custom-MuiMenu-list {
      right: 0;
    }

    .logo-div h3 {
      color: rgb(251, 150, 120) !important;
    }

    .left-menu {
      .MuiDrawer-paper {
        background-color: white !important;
      }

      .title {
        font-size: 20px;
      }

      .MuiPaper-root {
        width: 168px;
      }
    }

    .addAlarm,
    .addTimer {
      width: 100% !important;
      margin: 0 !important;


    }

    header .social-media {
      display: none;
    }





    .timepicker-div .field .MuiFormControl-root .MuiInputBase-formControl {
      height: 60px;
    }

    .timepicker-div {
      .field {
        .MuiInputAdornment-root {
          right: 30px !important;
        }
      }
    }

    .text-input .MuiInputBase-formControl {
      width: 100%;
    }

    .my-container {
      width: 330px;
    }

    .info-block-each,
    .alarm-box {
      width: 100%;
      margin-bottom: 20px;
    }

    .small-ad,
    .md-ad {
      width: 468px !important;
      height: 60px !important;
    }

    .switcher-main {
      top: -31px;
    }

    .embed-div {
      .switcher-main {
        top: 0px;
      }
    }

    .right-side-today-div {
      margin-right: 25px;
    }


    .bg-ad {
      width: 336px !important;
      height: 280px !important;
    }

    .info-block-each-text {
      width: 100%;
    }

    .rating-container {
      width: 523px !important;
    }

    .main-component {
      margin-left: 0;
      padding-left: 0;
      padding-right: 0;
    }

    .footer {
      .footer-container-inner {
        flex-direction: column-reverse;
        justify-content: center;
      }

      .social-media {
        width: 272px;
        margin-bottom: 20px;
        margin-left: 20px;

        ul {
          li {
            margin-right: 0;
          }
        }
      }

      .embed-btn-div {
        display: none;
      }
    }

    .top-header {
      width: 100%;
      border-radius: 0;
    }

    .MuiToolbar-root {
      padding-left: 0;
      padding-right: 0;
      margin-right: 20px;
      justify-content: space-around;
    }

    .MuiSelect-select .text-language-div {
      display: none;
    }

    .languages .MuiSelect-select {
      min-width: auto;
    }

    .mobile-logo {
      display: block;
      font-family: 'Arial';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      color: #152141;
      margin-left: -30px;
    }

    h2 {
      font-size: 24px !important;
    }

    .alarm-box .time {
      font-size: 32px;
    }

    .right-side-today-div {
      display: none;
    }

    .switcher-main {
      top: 26px;
    }

    .progress-bar-info {
      width: 170px;
      margin-top: 15px;
    }

    .flex-form {
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;

      .title,
      .field {
        width: 100% !important;

      }

      .title h5 {
        text-align: left;
        margin: 0 auto;
        margin-bottom: 16px;

      }
    }

    .timepicker-div .field .MuiFormControl-root .MuiInputBase-formControl {
      width: 100%;
    }

    .key_set_alarm {
      display: flex;
      justify-content: center;
    }

    .small-ad {
      width: 320px !important;
      height: 120px !important;
    }

    .md-ad {
      width: 320px !important;
      height: 280px !important;
    }

    .bg-ad {
      width: 320px !important;
      height: 480px !important;
    }

    .rating-container {
      width: 98% !important;
      margin: auto !important;

      .rating-container-inner {
        flex-direction: column;
        height: auto;
      }

      .delimeter-line {
        width: 260px;

        transform: rotate(180deg);
      }

      .total-rating-info {
        margin-left: auto;
        margin-right: auto;
      }
    }

    .countdown-container {
      .my-container {
        width: 100%;
      }
    }

    .timer-container {
      width: 360px !important;

      .addTimer {
        .flex-form {
          margin-bottom: 0 !important;


          .title {
            h5 {
              text-align: left;
              margin-top: 24px;
            }
          }

          .for-timer-inner-title {
            h5 {
              margin-top: 0;
            }
          }
        }
      }

      .common-times {
        width: 100%;
      }

      .available-timers {
        .alarm-box {
          height: 275px;
        }
      }

      .addTimer {
        padding: 0;
      }

      .addTimer .MuiInputBase-formControl,
      .addTimer .right-side .simple-select__control {
        // width: 330px !important;
      }

      .right-right-side {
        width: 330px !important;
        margin-left: 15px;


      }

      .image-block {
        width: 135px !important;
      }


      .left-side-content {
        flex-direction: column;
      }

      .snooze-btn-div,
      .error-btn-div {
        .m-right-15 {
          margin-right: 0;
        }
      }

      /* .field {
        .MuiFormControl-root {
          width: 330px !important;
        }
      }*/

      .addTimer .date-time .MuiBox-root input[type=text] {
        width: 282px !important;
      }

      h3 {
        padding-left: 15px;
      }

      .right-side {
        flex-direction: column;

        .left-right-side,
        .right-right-side {
          width: 100% !important;
        }

        .left-right-side {
          .hide_date_title {
            padding-left: 15px;
          }
        }
      }

      .addTimer .extra-field .MuiInputBase-formControl {
        // width: 282px !important;
      }

      .extra-field .MuiFormControl-root {
        width: 282px !important;
      }

      .left-side .style-div .simple-select__control {
        width: 178px !important;
      }

      .left-side .custom-timepicker {
        padding-right: 0 !important;
      }

      .date-time {
        width: 100% !important;
        padding-left: 15px;
        padding-right: 15px;

        .datepicker-input {
          padding-left: 0;
          padding-right: 0;
        }
      }

      .buttons-sm {
        flex-direction: column !important;
        //width: 360px !important;

        .btn-div,
        .regular-outlined-button {
          width: 96%;
        }

        .btn-div {
          margin-bottom: 20px;
        }
      }

      .extra-field {
        .timer-icon {
          margin-left: 8px;
        }
      }

      .date-time {
        flex-direction: column;
      }

      .preview-container {
        padding-bottom: 0;
        padding-top: 0;
        height: 144px;

        .title {
          font-size: 20px;
          line-height: 0;
          margin-top: 20px;
          margin-bottom: 8px;
        }

        .date {
          font-size: 13px;
          margin-top: 0;
        }

        .simple-display-operations-countdown {
          margin-top: 0;
        }
      }
    }
  }

  .bg-big,
  .bg-middle {
    display: none !important;
  }

  .bg-small {
    display: block;
  }



}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* {
  outline: 1px solid red;
}*/