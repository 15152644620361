$borderraduis:9px;
$borderraduis20:20px;
$menucolor: rgba(0, 0, 0, 0.87);
$whitecolor:white !important;
$fontsize16:16px !important;
$backgroundcolorMain: #fafbfb !important;
$flexycolorblue: rgb(3, 201, 215) !important;
$yellowcolor:rgb(251, 150, 120) !important;

.tools-app {
    .stopwatch-container .history-list td {
        line-height: 1.43;
        display: table-cell;
        vertical-align: inherit;
        border-bottom: 1px solid rgba(224, 224, 224, 1);
        padding-bottom: 10px;
        padding-top: 10px;
    }


    .extra-field {
        .MuiInputBase-root {
            input[type="text"] {
                line-height: 1.8;
            }
        }
    }

    .page-content-coantainer {
        box-shadow: 0px 4px 10px rgb(0 0 0 / 7%);
        background: white;
        border-radius: 20px;
        padding: 24px 28px;


    }

    .faq-container,
    .page-content-coantainer {
        a {
            display: contents;
            color: rgb(228, 106, 118) !important
        }
    }


    .history-list {
        thead {
            th {
                text-transform: capitalize;
            }
        }
    }

    .lap-history {
        .stopwatch-history-container {
            background: white;
            border-radius: $borderraduis20;
        }

    }



    .alarm-box {
        border-radius: 20px;
    }

    h1 {
        font-size: 30px !important;
        line-height: 45px !important;
        font-weight: 500 !important;
        width: 100%;
        text-align: center;
    }

    .sound-container {
        display: flex;
        align-items: center;
        justify-content: unset;
        flex-direction: row;

        .sound-buttons {
            margin-left: 10px;

            .operation-button {
                width: 30px;
                height: 30px;
            }

            &:hover {
                svg {
                    path {
                        fill: $flexycolorblue;
                    }

                }
            }
        }
    }

    .Mui-disabled {
        background-color: $whitecolor;
        color: inherit !important;
        -webkit-text-fill-color: rgba(0, 0, 0, 0.87);
    }

    fieldset {
        border-color: #E8E9EC !important;
    }

    .ok {
        color: $flexycolorblue;
    }

    .circle,
    .MuiIconButton-edgeEnd {
        width: 35px;
        height: 35px;
        padding: 5px;
    }

    .MuiAccordionSummary-root,
    .MuiAccordionDetails-root {
        background: $whitecolor;
    }

    .custom-timepicker {

        margin-top: 0 !important;

        .extra-field {
            // width: 100% !important;
            position: relative;
        }

        .timer-icon {
            // position: absolute;
            //  top: 0;
            // right: 0;
        }
    }



    .main-component {
        background-color: $backgroundcolorMain;
    }

    .addAlarm,
    .stopwatch-container .display-container,
    .timer-container .display-container,
    .currenttime-container .display-container {
        box-shadow: 0px 4px 10px rgb(0 0 0 / 7%);
        background: white;
        border-radius: $borderraduis20;

    }

    .info-block-each {
        border-radius: $borderraduis20;
        display: flex;
        align-items: center;

        .info-block-each-icon {
            svg {
                path {
                    fill: rgba(0, 0, 0, 0.54);
                }
            }
        }
    }

    .btn-div button {
        background: $flexycolorblue;
    }

    .active-lap,
    .yellow-btn-div {
        button {
            background: rgb(228, 106, 118) !important;
        }
    }

    .timer-container .common-times [role=group] .Mui-selected {
        background: $yellowcolor;
        border: none;
    }

    .timer-container .common-times [role=group] button {
        border: 1px solid $yellowcolor;
        color: $yellowcolor;
    }

    .simple-select__placeholder,
    .key_select_type label .MuiTypography-root {
        color: #152141 !important;
    }

    .countdown-container .preview-container,
    .bg-div-cover {
        border-radius: 20px;
    }

    .disabled-btn-div {
        button {
            pointer-events: none;
            background: transparent !important;
            transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            color: rgba(0, 0, 0, 0.26);
            border: 1px solid rgba(0, 0, 0, 0.26);
        }
    }

    .MuiButton-outlined {

        color: $flexycolorblue;
        background: $whitecolor;



        svg {
            path {
                fill: $flexycolorblue;
            }
        }
    }

    .btn-div {
        .MuiButton-outlined {
            color: $flexycolorblue;
            background: $whitecolor;
        }
    }

    .error-btn-div button {
        border: 1px solid #d32f2f;
        border-radius: 4px;
        color: #d32f2f;
        background: white !important;
    }

    .timer-container .common-times [role=group] .Mui-selected {
        color: $whitecolor;
    }


    .MuiList-root .MuiListItemButton-root:hover,
    .nav_active {
        border-radius: $borderraduis;
        background-color: $flexycolorblue;

        .MuiListItemText-root span {
            color: $whitecolor;
        }

        .MuiListItemIcon-root {
            svg {
                color: $whitecolor;
            }
        }
    }

    .MuiInputBase-formControl {
        padding: 11px 14px !important;
        height: auto !important;
        font-size: initial !important;
    }

    .key_set_timer_left {
        align-items: flex-start;
        display: flex;
    }

    .datepicker-input {
        .MuiInputAdornment-root {
            width: 35px;
            margin-left: 0;
        }

        .MuiIconButton-edgeEnd {
            width: 35px;
            height: 35px;
        }

        .MuiInputBase-formControl {
            height: 57px !important;
        }
    }

    .simple-select__control {
        height: 45px;
    }

    .sub-operations {
        .btn-div {
            button {
                height: 40px !important;
                font-size: 14px !important;
            }
        }
    }

    .available-timers {
        h3 {
            margin: 0 auto;
            text-align: left;
            font-size: 21px;
            line-height: 31.5px;
        }

        .countdown-div {
            height: auto !important;
            color: $yellowcolor;
        }

        .preview-container {
            border-radius: 20px;
        }
    }

    .alarm-box .time,
    .stopwatch-container .display-container .time-display-container .stopwatch__display,
    .stopwatch-container .display-container .time-display-container .span_display,
    .days-text {
        color: $yellowcolor;
    }

    .addTimer {
        .dropDown-div {
            // top: 85px;
        }
    }

    .circle:hover,
    .active-eco {
        border: 1px solid rgb(5, 178, 189) !important;
    }

    .timepicker-div .field .MuiFormControl-root .MuiInputBase-formControl .MuiInputAdornment-root {
        right: 20px;
    }

    .MuiClockPicker-arrowSwitcher .MuiIconButton-edgeEnd,
    .MuiClockPicker-arrowSwitcher .Mui-disabled,
    .MuiClockPicker-arrowSwitcher .MuiIconButton-root {
        border: none !important;
        width: 30px;
        height: 30px;
    }

    .MuiIconButton-edgeEnd {
        padding: 4px;
        grid-gap: 10px;
        gap: 10px;
        width: 30px;
        height: 30px;
        border: 1px solid $flexycolorblue;
    }

    .text-input .MuiInputBase-formControl {
        padding: 11px 14px !important;
        height: auto !important;
    }


    .MuiListItemText-root span {
        color: $menucolor;
        font-size: $fontsize16;
    }

    .addCountDown {
        display: initial !important;

        .bg-images-color {
            padding: 11px 14px !important;
            border: 1px solid $yellowcolor;
            border-radius: $borderraduis;
            margin-bottom: 28px;

            h5,
            h4 {
                font-size: 16px !important;
                text-align: left;
                line-height: 22px !important;
                margin-bottom: 8px !important;
            }
        }
    }

    .left-side {
        .datepicker-input {
            input[type="text"] {
                padding: 0;
            }
        }

        .date-time {
            margin-bottom: 0;
        }

        .simple-select__control {
            width: 271px;
        }
    }

    .left-menu {
        .MuiDrawer-paper {
            background-color: transparent;
        }
    }

}